<template>
  <div class="wap-order-evalute">
    <headerBar :title="$t('ping-jia-1')" @back="back"></headerBar>

    <div class="main wap-page-form">
      <div class="form-item">
        <div class="label">{{ $t('shang-pin-ping-fen') }}</div>
        <div class="input">
          <Rate :size="25" color="#ffd21e" v-model="form.ProductScore"></Rate>
        </div>
      </div>
      <div class="form-item">
        <div class="label">{{ $t('fu-wu-ping-fen') }}</div>
        <div class="input">
          <Rate :size="25" color="#ffd21e" v-model="form.ServiceScore"></Rate>
        </div>
      </div>
      <div class="form-item">
        <div class="label">{{ $t('wu-liu-ping-fen') }}</div>
        <div class="input">
          <Rate :size="25" color="#ffd21e" v-model="form.LogisticsScore"></Rate>
        </div>
      </div>
      <div class="form-item">
        <div class="label">{{ $t('ping-jia-tu-pian-0') }}</div>
        <div class="input">
          <el-upload
            style="width: 100px; height: 100px"
            :show-file-list="false"
            :action="uploadUrl"
            :headers="headers"
            name="file"
            :on-success="uploadSuccess"
            :before-upload="beforeUpload"
          >
            <img
              v-if="form.Image"
              :src="getBaseUrl(form.Image)"
              class="avatar"
            />
            <i
              v-else
              class="el-icon-camera-solid"
              style="font-size: 30px; color: #aaa"
            ></i>
          </el-upload>
        </div>
      </div>
      <div class="form-item">
        <div class="label">{{ $t('ping-jia-0') }}</div>
        <div class="input">
          <Field
            v-model="form.Content"
            type="textarea"
            :placeholder="$t('qing-shu-ru-ping-jia-nei-rong-2')"
          >
          </Field>
        </div>
      </div>

      <div class="form-btn">
        <Button class="submit-btn primary" @click="submit">{{
          $t('ti-jiao')
        }}</Button>
      </div>
    </div>
  </div>
</template>
<script>
import { Button, Popup, Rate, Field } from 'vant'
import { addEvalute, addGoodsEvalute } from '@/api/shop'
import { getToken } from '@/utils/auth'
import headerBar from '@/components/header'
import moment from 'moment'
import { getBaseUrl } from '@/utils/common'
export default {
  components: {
    Button,
    headerBar,
    Rate,
    Popup,
    Field
  },
  data() {
    return {
      carUrl: require('@/assets/imgs/icon-car.png'),
      messageUrl: require('@/assets/imgs/icon-message.png'),
      dataId: '',
      productId: '',
      headers: {},
      form: {
        ProductScore: 0,
        ServiceScore: 0,
        LogisticsScore: 0,
        Image: '',
        Content: ''
      }
    }
  },
  computed: {
    langList() {
      return this.$store.state.langList
    },
    lang() {
      return this.$store.state.lang
    },
    uploadUrl() {
      return this.$store.state.uploadUrl
    },
    imgType() {
      return this.$store.state.imgType
    }
  },
  mounted() {
    this.dataId = this.$route.query.id
    this.productId = this.$route.query.productId
    this.headers = {
      Token: getToken()
    }
  },
  methods: {
    getBaseUrl,
    back() {
      this.$router.go(-1)
    },
    submit() {
      if (!this.form.ProductScore) {
        this.$toast(this.$t('qing-xuan-ze-shang-pin-ping-fen'))
        return
      }
      if (!this.form.ServiceScore) {
        this.$toast(this.$t('qing-xuan-ze-fu-wu-ping-fen'))
        return
      }
      if (!this.form.LogisticsScore) {
        this.$toast(this.$t('qing-xuan-ze-wu-liu-ping-fen'))
        return
      }
      if (!this.form.Content) {
        this.$toast(this.$t('qing-shu-ru-ping-jia-nei-rong-0'))
        return
      }
      addEvalute({
        OrderId: parseFloat(this.dataId),
        ProductScore: this.form.ProductScore,
        ServiceScore: this.form.ServiceScore,
        LogisticsScore: this.form.LogisticsScore,
        Content: this.form.Content,
        Image: this.form.Image || null
      }).then((res) => {
        if (res.code == 0) {
          this.$toast(this.$t('ping-jia-cheng-gong'))
          this.form = {
            ProductScore: 0,
            ServiceScore: 0,
            LogisticsScore: 0,
            Image: '',
            Content: ''
          }
          setTimeout(() => {
            this.back()
          }, 1500)
        } else {
          this.$toast(res.msg)
        }
      })

      // 商品评论
      addGoodsEvalute({
        ProductID: this.productId,
        Score: this.form.ProductScore,
        Content: this.form.Content
      })
    },
    uploadSuccess(res) {
      this.form.Image = res.data.FileName
      this.$forceUpdate()
    },
    beforeUpload(file) {
      let name = file.name
      let type = name.substring(name.indexOf('.') + 1)
      if (this.imgType.indexOf(type) === -1) {
        this.$message.error(this.$t('zhi-neng-shang-chuan-tu-pian'))
        return false
      }
      return true
    }
  }
}
</script>